import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import "./styles.scss";
import { CardRendererInterface } from "../models";
import { IMAGE_SIZES } from "../../../constants";

const PersonCard: FunctionComponent<CardRendererInterface> = ({ card, listingType }) => {
  const isBusinessListing = listingType === "personBusiness";
  const [imageWidth, imageHeight] = isBusinessListing ? [450, 450] : [200, 200];

  const getProggram = () => {
    return card.personListItem ? (
      <p>{card.person.programWithYear}</p>
    ) : (
      card.person.programWithYear?.split(",").map((item, index) => <p key={index}>{item}</p>)
    );
  };

  const personSocialLink =
    card.person.socials &&
    (card.person.socials.filter((social: { platform: string }) => social.platform === "Website")[0] ||
      card.person.socials[0]);

  return (
    <div
      className={`c-personCard ${listingType} ${card.personListItem ? "person-business-full" : ""} ${
        isBusinessListing && card.bgColor ? "theme--" + card.bgColor.title : ""
      }`}
    >
      <a className="c-personCard__link" href={personSocialLink?.link}>
        {!card.personListItem && card.person.image ? (
          <div className={`c-personCard__image smb-16 `} aria-hidden={true}>
            <SanityImage
              {...card.person.image}
              sizes={IMAGE_SIZES.CARD}
              width={imageWidth}
              height={imageHeight}
              htmlWidth={imageWidth}
              htmlHeight={imageHeight}
              className={`img-fluid person-image`}
            />
            {isBusinessListing && card.person.companyLogo && (
              <SanityImage
                {...card.person.companyLogo}
                sizes={IMAGE_SIZES.CARD}
                width={imageWidth}
                htmlWidth={imageWidth}
                htmlHeight={imageHeight}
                className={`img-fluid logo-image`}
              />
            )}
          </div>
        ) : null}
        <h3 className={`c-personCard__title smb-16`}>{card.person.name}</h3>
        <p className="c-personCard__label smb-16">
          {card.person.company ? card.person.company : personSocialLink?.link}
        </p>
        <div className="c-personCard__description">{isBusinessListing ? getProggram() : <p>{card.description}</p>}</div>
      </a>
    </div>
  );
};

export default PersonCard;
