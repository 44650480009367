import React, { FunctionComponent, useState, useEffect, useContext, ChangeEvent } from "react";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { graphql, useStaticQuery } from "gatsby";
import Section from "../Section";
import { PersonType } from "../CaseStudy";
import PersonCard from "../CardRenderer/PersonCard";
import { CardType } from "../CardRenderer/models";
import "./styles.scss";

export interface PersonListFullInterface {
  heading?: string;
  description?: string;
  bgColor?: {
    title: string;
    value: string;
  };
  categorySelectLabel?: string;
}

export type PersonInterface = {
  personListItem: boolean;
  person: PersonType;
  description: string;
  category: string;
};

export type PersonCategoryInterface = {
  _id: string;
  categoryName: string;
};

const PersonListFull: FunctionComponent<PersonListFullInterface> = ({
  heading,
  description,
  bgColor,
  categorySelectLabel
}) => {
  const searchLabels = useContext(LocalizedContext).sanityLabels?.searchLabels;

  const usePersonListData = () => {
    const persons = useStaticQuery(graphql`
      query Persons {
        allSanityPerson {
          nodes {
            name
            company
            programWithYear
            category {
              categoryName
              _id
            }
            designation
            socials {
              link
              platform
            }
          }
        }
        allSanityPersonCategory {
          nodes {
            _id
            categoryName
          }
        }
      }
    `);

    const categoryList = persons?.allSanityPersonCategory?.nodes || [];
    const personList = persons?.allSanityPerson?.nodes || [];

    const personDataList =
      personList && personList.length
        ? personList
            .map((item: any) => {
              return {
                personListItem: true,
                person: item,
                description: item.programWithYear,
                category: item.category?.categoryName
              };
            })
            .filter((person: PersonInterface) => person.category)
        : [];

    return [categoryList, personDataList];
  };

  const [personCategories, personList] = usePersonListData();

  const [filteredPersonList, setFilteredPersonList] = useState(personList);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  useEffect(() => {
    setFilteredPersonList(
      personList.filter(
        (item: PersonInterface) =>
          (!searchKeyword ||
            (item.person?.name && item.person?.name.toLowerCase().includes(searchKeyword.toLowerCase())) ||
            (item.person?.company && item.person?.company.toLowerCase().includes(searchKeyword.toLowerCase())) ||
            (item.person?.program && item.person?.program.toLowerCase().includes(searchKeyword.toLowerCase()))) &&
          (!searchCategory || item.category == searchCategory)
      )
    );
  }, [searchKeyword, searchCategory]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleSubmit = () => {
    setSearchKeyword(searchKeyword);
  };

  const handleSelectChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchCategory(value);
  };

  return (
    <Section sectionClassName="person-list" title={heading} theme={bgColor?.title || ""} description={description}>
      <div className="person-list__filters spb-24 spb-xl-36">
        <div className="person-list__filters-search person-list__filters-block">
          <input
            autoFocus={true}
            className="search-input"
            type="search"
            name="search"
            placeholder={searchLabels?.searchPlaceholder}
            onChange={handleChange}
          />
          <input className="search-submit-button" type="submit" value=" " onClick={handleSubmit} />
        </div>
        <div className="person-list__filters-category person-list__filters-block">
          <p className="person-list__filters-label">{categorySelectLabel ? categorySelectLabel : ""}</p>
          {personCategories && personCategories.length ? (
            <select className="person-list__select" onChange={handleSelectChange} value={searchCategory}>
              <option value={""} key={1}>
                {searchLabels?.filterAll}
              </option>
              {personCategories.map((category: PersonCategoryInterface, index: number) => (
                <option value={category.categoryName} key={index}>
                  {category.categoryName}
                </option>
              ))}
            </select>
          ) : null}
        </div>
      </div>
      <div className="person-list__list">
        <div className="grid-row grid-row-center">
          {filteredPersonList?.length
            ? filteredPersonList.map((card: CardType, index: number) => (
                <div className={`person-card grid-col-12 grid-col-md-6 grid-col-xl-3`} key={index}>
                  <PersonCard card={card} listingType="personListing" />
                </div>
              ))
            : null}
        </div>
      </div>
    </Section>
  );
};

export default PersonListFull;
